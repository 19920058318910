import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

export const ItemDetailsTable = (props) => {
  const ItemDetailsTableRow = (key) => {
    return (
      <TableRow key={key}>
        <TableCell>{key}</TableCell>
        <TableCell>{JSON.stringify(props.item[key])}</TableCell>
      </TableRow>
    )
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Item details table">
        <TableBody>
          {Object.keys(props.item).map(key => {
            return ItemDetailsTableRow(key)
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
