import * as React from 'react';
import {
  Box,
  Typography
} from '@mui/material';

export const Privacy = () => {

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h3', sm: 'h3', md: 'h2', lg: 'h2', textAlign: 'left' } }} color="inherit">
          Brand-Chain customer privacy notice
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
          Brand-Chain customer privacy notice
        </Typography>

        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h4', sm: 'h4', md: 'h3', lg: 'h3', textAlign: 'left' } }} color="inherit">
          Our contact details
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
          hello@brand-chain.com
        </Typography>

        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h4', sm: 'h4', md: 'h3', lg: 'h3', textAlign: 'left' } }} color="inherit">
          What information we collect, use, and why
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
        We collect or use the following information to provide services and goods, including delivery:
        •	Purchase or account history
        •	Account information
        •	Website user information (including user journeys and cookie tracking)

        We collect or use the following information for the operation of customer accounts and guarantees:
        •	Account information, including registration details

        We collect or use the following information to prevent crime, prosecute offenders, or defend against legal action:
        •	Customer or client accounts and records
        •	Ownership history
        </Typography>

        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h4', sm: 'h4', md: 'h3', lg: 'h3', textAlign: 'left' } }} color="inherit">
          Lawful bases
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
        Our lawful bases for collecting or using personal information to provide services and goods are:
        •	Consent

        Our lawful bases for collecting or using personal information for the operation of customer accounts and guarantees are:
        •	Consent

        Our lawful bases for collecting or using personal information to prevent crime, prosecute offenders or defend against legal action are:
        •	Consent
        </Typography>

        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h4', sm: 'h4', md: 'h3', lg: 'h3', textAlign: 'left' } }} color="inherit">
          Where we get personal information from
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
        •	People directly
        •	Retailers and verification companies for goods purchased by the user
        </Typography>

        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h4', sm: 'h4', md: 'h3', lg: 'h3', textAlign: 'left' } }} color="inherit">
          How long we keep information
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
          We keep your information for the duration that your account is active.
        </Typography>

        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h4', sm: 'h4', md: 'h3', lg: 'h3', textAlign: 'left' } }} color="inherit">
          Your data protection rights
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
        Under data protection law, you have rights including:
        Your right of access - You have the right to ask us for copies of your personal data.
        Your right to rectification - You have the right to ask us to rectify personal data you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.
        Your right to erasure - You have the right to ask us to erase your personal data in certain circumstances.
        Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal data in certain circumstances.
        Your right to object to processing - You have the right to object to the processing of your personal data in certain circumstances.
        Your right to data portability - You have the right to ask that we transfer the personal data you gave us to another organisation, or to you, in certain circumstances.
        Your right to withdraw consent – When we use consent as our lawful basis you have the right to withdraw your consent.
        You don’t usually need to pay a fee to exercise your rights. If you make a request, we have one calendar month to respond to you.
        To make a data protection rights request, please contact us using the contact details at the top of this privacy notice.
        </Typography>

        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h4', sm: 'h4', md: 'h3', lg: 'h3', textAlign: 'left' } }} color="inherit">
          How to complain
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
        If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details at the top of this privacy notice.
        If you remain unhappy with how we’ve used your data after raising a complaint with us, you can also complain to the ICO.
        The ICO’s address:           
        Information Commissioner’s Office
        Wycliffe House
        Water Lane
        Wilmslow
        Cheshire
        SK9 5AF
        Helpline number: 0303 123 1113
        Website: https://www.ico.org.uk/make-a-complaint
        </Typography>

        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h4', sm: 'h4', md: 'h3', lg: 'h3', textAlign: 'left' } }} color="inherit">
          Last updated
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
          17 May 2024
        </Typography>
      </Box>
    </>
  );
}
