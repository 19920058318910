import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { API } from 'aws-amplify';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ItemDetailsTable } from '../components/ItemDetailsTable';
import { ItemLedgerTable } from '../components/ItemLedgerTable';

const oppositeStatuses = {
  enabled: 'disabled',
  disabled: 'enabled',
}

export const Item = () => {
  const { item_id } = useParams()
  const { route, signOut, user } = useAuthenticator((context) => [context.route]);
  const [fetchError, setFetchError] = useState({});
  const [response, setResponse] = useState({});
  const [buttonPressed, setButtonPressed] = useState(0);
  const [open, setOpen] = React.useState({ createPOACode: false, transferItem: false });
  const handleOpen = (buttonText) => setOpen({ ...open, [buttonText]: true });
  const handleClose = (buttonText) => setOpen({ ...open, [buttonText]: false });

  console.log(response);

  const callApi = (path, method, name, data={}) => {
    const handleSuccess = (request) => {
      const currentResponse = {...response};
      currentResponse[name] = (request);
      setResponse(currentResponse);
      const currentError = {...fetchError};
      currentError[name] = null
      setFetchError(currentError);
    }
    const handleError = (e) => {
      if(!API.isCancel(e)) {
        console.log('e', e);
        console.log(e.response);
        console.log(e.response.data);
        console.log(e.message);
        const currentResponse = {...response};
        currentResponse[name] = null;
        setResponse(currentResponse);
        const currentError = {...fetchError};
        currentError[name] = JSON.stringify(e.response.data);
        setFetchError(currentError);
      }
    }

    if (method === 'get') {
      return API.get('account', path, {})
      .then((request) => {
        handleSuccess(request)
      })
      .catch((e) => {
        handleError(e)
      });
    } else {
      return API.post('account', path, {
        body: data
      })
      .then((request) => {
        handleSuccess(request)
      })
      .catch((e) => {
        handleError(e)
      });
    }

  };

  useEffect(() => {
    const req = callApi(`/item/${item_id}`, 'get', 'getItem');
    return () => {
      API.cancel(req);
    }
  }, [item_id, buttonPressed]);


  const handleSubmit = (e, url, method, name, fields) => {
    e.preventDefault();

    console.log({url, method, name})
    const form = {};
    fields.forEach(field => {
      console.log(JSON.stringify(e.target.elements[field].value))
      form[field] = e.target.elements[field].value
    })
    form['requester_user'] = user.username

    const req = callApi(
      url,
      method,
      name,
      form
    )
  };

  const ActionButton = (props) => {
    return (
      <>
        <Button variant="outlined" onClick={() => {handleOpen(props.buttonText)}}>{props.buttonText}</Button>
        <Dialog
          open={open[props.buttonText]}
          onClose={() => {handleClose(props.buttonText)}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle>{props.buttonText}</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              autoComplete="off"
              id={`${props.buttonText}Form`}
              onSubmit={(e) => {
                handleSubmit(e, props.urlPath, props.method, props.buttonText, props.fields)
              }}
            >
              {props.fields.map(field => {
                return (
                  <TextField key={field} name={field} variant="outlined" label={field} required fullWidth />
                )
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {handleClose(props.buttonText)}}>Cancel</Button>
            <Button form={`${props.buttonText}Form`} type="submit">Submit</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const POAStatusButton = (props) => {
    const buttonText = `${oppositeStatuses[props.currentStatus].slice(0, -1)} POA`;
    const body = {
      "poa_status": oppositeStatuses[props.currentStatus]
    };
    const color = props.currentStatus === 'enabled' ? 'success' : 'error';
    return (
      <Button variant="outlined" color={color} onClick={() => {
        callApi(props.urlPath, props.method, 'poaStatus', body).then(() => {
          setButtonPressed(buttonPressed + 1)
        })
      }}>
        {buttonText}
      </Button>
    )
  }

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.primary.main,
    height: '100%',
    lineHeight: '60px',
  }));

  return (
    <>
      <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid xs={4} sm={8} md={12}>
          <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ alignItems: 'center' }}>
            <Grid xs={4} sm={4} md={6}>
              <Box>
                {
                  response && response['getItem'] && response['getItem']['itemDetails'] &&
                  (<Typography
                    variant="h3"
                    component="h3"
                    align="left"
                  >
                    {response['getItem']['itemDetails'].item_name}
                  </Typography>)
                }
              </Box>
            </Grid>
            <Grid xs={4} sm={4} md={6}>
                <Stack spacing={2} direction="row" sx={{float: "right"}}>
                  {response && response['getItem'] &&
                    (user && (user.username !== response['getItem']['itemDetails'].username)) &&
                    (response['getItem']['itemDetails'].poa_status === 'enabled') &&
                    (<ActionButton
                      buttonText="createPOACode"
                      urlPath={`/item/${item_id}/verify`}
                      method="post"
                      fields={[
                        'platform_username',
                        'platform',
                        'poa_code'
                      ]}
                    />)
                  }
                  {(response && response['getItem'] && user && user.username === response['getItem']['itemDetails'].username) &&
                    <POAStatusButton
                      currentStatus={response['getItem']['itemDetails'].poa_status}
                      urlPath={`/item/${item_id}/update`}
                      method="post"
                    />
                  }
                  {(response && response['getItem'] && user && user.username === response['getItem']['itemDetails'].username) &&
                    <ActionButton
                      buttonText="transferItem"
                      urlPath={`/item/${item_id}/transfer`}
                      method="post"
                      fields={[
                        'recipient',
                        'recipient_transfer_code'
                      ]}
                    />
                  }
                </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={4} sm={4} md={4}>
          {
            (response && response['getItem']) && response['getItem']['itemDetails'] &&
            (<img
              src={response['getItem']['itemDetails']['image_url']}
              alt="increase priority"
              width={"100%"}
            />)
            // (<Paper
            //   square
            //   elevation={1}
            //   sx={{
            //     position: 'relative',
            //     backgroundColor: 'red',
            //     color: '#fff',
            //     backgroundSize: 'cover',
            //     backgroundRepeat: 'no-repeat',
            //     backgroundPosition: 'center',
            //     backgroundImage: `url(${response['getItem']['itemDetails']['image_url']})`
            //   }}
            // >
            //   <Box
            //     sx={{
            //       height: 0,
            //       pb: '100%'
            //     }}
            //   >
                
            //   </Box>
            // </Paper>)
          }
        </Grid>
        <Grid xs={4} sm={4} md={8}>
          <Item elevation={1}>
            {response['getItem'] && (<ItemDetailsTable item={response['getItem']['itemDetails']}/>)}
          </Item>
        </Grid>
        <Grid xs={4} sm={8} md={12}>
          <Item elevation={1}>
            {response['getItem'] && response['getItem']['logged_in'] && (<ItemLedgerTable item={response['getItem']['itemLedger']}/>)}
          </Item>
        </Grid>
      </Grid>
    </>
  )
}
