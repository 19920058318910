import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

export const ItemLedgerTable = (props) => {
  const SaucyBit = (key) => {
    return (
      <TableRow key={key}>
        <TableCell>{key}</TableCell>
        <TableCell>{JSON.stringify(props.item[key])}</TableCell>
      </TableRow>
    )
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>key</TableCell>
            <TableCell>contents</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(props.item).map(key => SaucyBit(key))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
