import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material';

export const MediaCard = (props) => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardActionArea onClick={() => {
        navigate(`/item/${props.item.item_id}`)
      }}>
        <CardMedia
          sx={{ height: 200 }}
          image={props.item.image_url}
          title="Item image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.item.item_name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.item.item_description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
