import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  IconButton,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export const Deletion = () => {
  const [expanded, setExpanded] = React.useState([false, false, false]);
  const navigate = useNavigate();

  const handleExpandClick = (index) => {
    const currentExpanded = [...expanded];
    currentExpanded[index] = !expanded[index];
    setExpanded(currentExpanded);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h4', sm: 'h4', md: 'h3', lg: 'h3', textAlign: 'left' } }} color="inherit">
          Deleting your account
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
          To delete your account:

          - Login to your account
          - Go to manage profile
          - Click "Delete account"
        </Typography>
        <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'p', sm: 'p', md: 'p', lg: 'p', textAlign: 'left' } }} color="inherit">
          Upon deletion of your account, your account information will be removed, however item information will remain on the ledger.
        </Typography>
      </Box>
    </>
  );
}
