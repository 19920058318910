import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  IconButton,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const Home = () => {
  const [expanded, setExpanded] = React.useState([false, false, false]);
  const navigate = useNavigate();

  const handleExpandClick = (index) => {
    const currentExpanded = [...expanded];
    currentExpanded[index] = !expanded[index];
    setExpanded(currentExpanded);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, mb: 6, height: { xs: 600, sm: 800, md: 1200, lg: 800 }}}>
        <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={12} lg={4}>
              <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h3', sm: 'h2', md: 'h1', lg: 'h1' , textAlign: 'left' } }} color="inherit">
                Ownership history for Luxury and Collectible goods
              </Typography>
              <Button variant="outlined" onClick={() => navigate('/account')}>Sign Up</Button>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={8}>
              <img width={'100%'} src='/store.jpg'/>
            </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
      <Typography sx={{ ml:3, mt:3, mb:3, flexGrow: 1, typography: { xs: 'h3', sm: 'h3', md: 'h2', lg: 'h2', textAlign: 'left' } }} color="inherit">
        Increase transparency when selling on second hand platforms
      </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={12} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="194"
                image="/bag2.jpg"
                alt="Photo by Content Pixie on Unsplash"
              />
              <CardContent sx={{typography: { textAlign: 'left' }}}>
                <Typography gutterBottom variant="h5" component="div" color={'primary'}>
                  Prove it's real
                </Typography>
                <Typography paragraph>
                  View item details and leger entries, including where they were registered on the system.
                </Typography>
                <Typography paragraph>
                  Only items that have been verified by, or purchased from, approved partners have a Brand-Chain page.
                </Typography>
                <Typography paragraph>
                  This helps us to ensure that all items listed on Brand-Chain are real.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid xs={12} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="194"
                image="/watches.jpg"
                alt="Photo by John Torcasio on Unsplash"
              />
              <CardContent sx={{typography: { textAlign: 'left' }}}>
                <Typography gutterBottom variant="h5" component="div" color={'primary'}>
                  Prove you own it
                </Typography>
                <Typography paragraph>
                  Seen an item for sale on a second hand marketplace?
                  Confirm that it is really the owner listing it by sending them a one time code.
                </Typography>
                <Typography paragraph>
                  The item listing on Platform X (e.g. eBay) will need to include a Brand-Chain link to the item page
                  and the owner must have proof of authenticity enabled for the item.
                </Typography>
                <Typography paragraph>
                  Potential purchasers create and a send a one time code to the owner through Brand-Chain,
                  including details of the platform they have seen it listed on and their username on that platform.
                </Typography>
                <Typography paragraph>
                  The owner can then reply to the potential purchaser on Platform X with the code.
                  Potential purchasers can then be confident that the seller on Platform X is the owner on Brand-Chain.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid xs={12} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="194"
                image="cards.jpg"
                alt="Photo by Mediamodifier on Unsplash"
              />
              <CardContent sx={{typography: { textAlign: 'left' }}}>
                <Typography gutterBottom variant="h5" component="div" color={'primary'}>
                  Transfer after sale
                </Typography>
                <Typography paragraph>
                  Once a purchase is successful, the seller transfers the item to the buyer and a new ledger entry is made.
                </Typography>
                <Typography paragraph>
                    Once an item has been transferred it will show in the account of the new owner,
                    and a new ledger entry is created confirming the transfer of ownership.
                  </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
