import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { RequireAuth } from './components/RequireAuth';
import { themeOptions } from './components/theme'
import { Layout } from './routes/Layout';
import { Home } from './routes/Home';
import { Account } from './routes/Account';
import { Item } from './routes/Item';
import { Login } from './routes/Login';
import { Privacy } from './routes/Privacy';
import { Deletion } from './routes/Deletion';

const defaultTheme = createTheme(themeOptions);

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="/account"
            element={
              <RequireAuth>
                <Account />
              </RequireAuth>
            }
          />
          <Route
            path="/item/:item_id"
            element={
              <Item />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/deletion" element={<Deletion />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Authenticator.Provider>
        <MyRoutes />
      </Authenticator.Provider>
    </ThemeProvider>
  );
}

export default App;
