import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import Grid from '@mui/material/Unstable_Grid2';
import { MediaCard } from '../components/MediaCard'

export const Account = () => {
  const { route, signOut, user } = useAuthenticator((context) => [context.route]);
  const [fetchError, setFetchError] = useState({});
  const [response, setResponse] = useState({});


  const callApi = (path, method, name, data={}) => {
    return API.get('account', path, {})
      .then((request) => {
        const currentResponse = {...response};
        currentResponse[name] = JSON.stringify(request);
        setResponse(currentResponse);
        const currentError = {...fetchError};
        currentError[name] = null
        setFetchError(currentError);
      })
      .catch((e) => {
        if(!API.isCancel(e)) {
          console.log(e);
          const currentResponse = {...response};
          currentResponse[name] = null;
          setResponse(currentResponse);
          const currentError = {...fetchError};
          currentError[name] = JSON.stringify(e.response);
          setFetchError(currentError);
        }
      });
  };

  useEffect(() => {
    const req = callApi(`/user/${user.username}`, 'get', 'getUser');
    return () => {
      API.cancel(req);
    }
  }, [user.username]);

  return (
    <div>
      <br/>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {(response && response['getUser'] && JSON.parse(response['getUser'])['userItems'].length > 0) &&
          (JSON.parse(response['getUser'])['userItems'] || []).map((userItem, index) => (
            <Grid xs={2} sm={4} md={4} key={index}>
              <MediaCard item={userItem} />
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
}
